import { useUserMetadata } from '../../services/userMetadata/useUserMetadata';
import { useAuthenticationService } from '../useService/UseService';

const defaultLanguage = localStorage.getItem('language') ?? 'en-US';

const availableLanguages = {
  en: 'en-US',
  es: 'es-419',
  pt: 'pt-BR',
  id: 'id-ID',
  ko: 'ko-KR',
  fr: 'fr-FR',
};

export type PrefferedLanguageInstance = {
  preferredLanguage: string | undefined;
  defaultLanguage: string;
  isLoading: boolean;
  hasError: boolean;
};

export function usePreferredLanguageV2(): PrefferedLanguageInstance {
  const { data, isLoading, hasError } = useUserMetadata();
  const { language } = useAuthenticationService().getUserCountryAndLanguage();

  const userDefaultLanguage = availableLanguages[language?.split('-')[0]] ?? defaultLanguage;

  const preferredLanguage = data?.profile?.preferredLanguage;

  return {
    preferredLanguage,
    defaultLanguage: userDefaultLanguage,
    isLoading,
    hasError
  };
}
